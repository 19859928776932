import { Divider } from '@mybridge/ui/divider';
import { ScaleFade, SlideFade } from '@mybridge/ui/transitions';
import { useDispatch } from 'react-redux';
import { PostFormBody } from 'v4/components/post-form/body';
import {
  PostFormContext,
  usePostFormContext,
} from 'v4/components/post-form/context';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Spinner } from '@mybridge/ui';
import { PostFeelingAndTagsText } from 'v4/components/post-form/feeling-tag-text';

import { PostFormFooter } from 'v4/components/post-form/footer';
import { PostFormHeader } from 'v4/components/post-form/header';
import { openCreatePostModal, triggerFetchPosts } from 'packages/mybridgev4/store/actions/post.actions';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@mybridge/ui/modal';
import PublishAsComponent from 'v4/components/articles/publish-as-modal';
import { useSelector } from 'react-redux';
import { PostVisibilityMenu } from 'v4/components/post-form/post-visibility-menu';
import { useContext, useEffect, useState } from 'react';
import { setTextRange } from 'typescript';
import { isDesktop } from 'react-device-detect';

export const DashboardPostForm = ({ companyDetails, ...props }) => {

  const dispatch = useDispatch();
  const refetchAll = () => {
    dispatch(triggerFetchPosts(true));
  };

  const postFormContext = usePostFormContext({
    refetchAll,
    ...props,
  });
  // const { multiPost, setMultiPost } = useContext(PostFormContext) ?? {};
  const {
    submitPost,
    gifs,
    images,
    resetForm,
    videos,
    mode,
    companyId,
    currentPostId,
    visibility,
    formContainerRef,
    expanded,
    publishArticleDisc,
    feeling: feelings,
    tagged,
    setMultiPost,
    multiPost,
    text,
    setText,
    setExpanded,
    postEditorData,
    setPostEditorData,
    EditorDefaultState,
    isLoading,
  } = postFormContext ?? {};
  const { feelingsAndActivitiesArr } = useSelector((s) => s.globalData) ?? {};

  const feeling = feelingsAndActivitiesArr?.find?.((v) => v.id == feelings?.id);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { createPostModalOpen } = useSelector((state) => state.posts);

  const [openPostModal, setOpenPostModal] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(true);
  // const [headerFields, setHeaderFields] = useState([{ id: 1, text: "" }]);

  const addHeaderField = () => {
    setMultiPost([
      ...multiPost,
      {
        text,
        gifs,
        images,
        videos,
        tagged,
        feeling,
        mode,
        companyId,
        id: currentPostId,
        visibility,
      },
    ]);
    // setPostEditorData([...postEditorData, { id: postEditorData.length, editorState: EditorDefaultState }])
    resetForm();
  };

useEffect(() => {
  // Use a single effect to handle the opening logic
  if (props?.isFromProfile || createPostModalOpen) {
    setOpenPostModal(true);
  } else {
    setOpenPostModal(false);
  }
}, [props?.isFromProfile, createPostModalOpen]);

  const { companyPageDetail } = useSelector((state) => state.companyPage);

  return (
    <PostFormContext.Provider value={{ ...(postFormContext ?? {}) }}>
      {!props?.isFromProfile && isDesktop ? (
        <Stack
          ref={formContainerRef}
          minH={expanded ? '40px' : '40px'}
          transition="all 0.2s"
        >
          <Stack w="100%">
            {!openPostModal && (
              <PostFormHeader
                companyDetails={companyPageDetail ?? companyDetails}
                setOpenPostModal={setOpenPostModal}
              />
            )}
            {/* <SlideFade in={expanded}>
            {expanded ? (
              <PostFormBody w="100%" overflow={expanded ? '' : 'hidden'} />
            ) : (
              ''
            )}
          </SlideFade> */}
          </Stack>
        </Stack>
      ) : null}
      <Modal
        isOpen={openPostModal}
        onClose={() => {
          setOpenPostModal(false);
          dispatch(openCreatePostModal(false))
          props?.onClose?.();
        }}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent minW={['auto', '43.3rem']} mt={["52px", '80px']}>
          {isLoading && (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: '1',
                opacity: 0.7,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="lg" />
            </div>
          )}
          <Stack mt={1} display={''}>
            <ModalHeader
              color="#3D5A80"
              fontSize="lg"
              textAlign="center"
              fontWeight="400"
            >
              Create post
            </ModalHeader>
            <ModalCloseButton onClick={() => setOpenPostModal(false)} />
          </Stack>
          <Divider width="89%" marginLeft="31px" />
          <ModalBody p={4} py={5} mt={0}>
            <PostFormHeader
              companyDetails={companyPageDetail}
              placeholderText={placeholderText}
              multiPost={multiPost}
              setMultiPost={setMultiPost}
              isPostModalOpen={openPostModal}
            />
            <PostFormBody w="100%" mb={3} />
            <Stack mt={1} display={''}>
              <Box flexWrap="wrap" mb={3}>
                <PostFeelingAndTagsText
                  companyDetails={companyPageDetail}
                  tagged={tagged}
                  feeling={feeling}
                />
              </Box>
              <HStack mb={5}>
                <PostVisibilityMenu
                  handleClose={() => setOpenPostModal(false)}
                  addHeaderField={addHeaderField}
                />
              </HStack>
              {isDesktop && <Divider />}
              <Stack mt={5}>
                <PostFormFooter />
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal {...publishArticleDisc}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <PublishAsComponent onClose={() => publishArticleDisc.onClose()} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </PostFormContext.Provider>
  );
};
