/* eslint-disable @nx/enforce-module-boundaries */
import {
  HStack,
  Heading,
  Stack,
  VStack,
  Radio,
  Text,
  Box,
  Button,
  RadioGroup,
} from '@mybridge/ui';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';
import PublishUserCompanyCard from './publish-card';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { StackDivider } from '@chakra-ui/react';
import {
  IndividualArticleIcon,
  MyBridgeArticleIcon,
  MyBridgeMewsIcon,
} from '@mybridge/icons';
import { PostFormContext } from 'v4/components/post-form/context';
import { useRouter } from 'next/router';
import {
  setPublishingToData,
  setPublishingAsData,
} from 'v4/store/slices/blogSlice';

const PublishAsComponent = ({ onClose, isPageComp }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { publishArticleDisc } = useContext(PostFormContext);
  const { companyPageList, loading } = useSelector(
    (state) => state.companyPage
  );
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { publishingToData, publishingAsData } = useSelector(
    (state) => state.blog
  );
  const [selectedUser, setSelectedUser] = useState(userProfileInfo?.id);
  const [publishTo, setPublishTo] = useState('1');

  useEffect(() => {
    dispatch(getAllCompanyPages());
  }, []);

  useEffect(() => {
    if (publishingAsData) {
      setSelectedUser(publishingAsData);
    }
    if (publishingToData) {
      setPublishTo(publishingToData);
    }
  }, [publishingAsData, publishingToData]);

  const handleToChange = (e) => {
    setPublishTo(e);
    dispatch(setPublishingToData(e));
  };

  const handleNextStep = () => {
    publishArticleDisc.onClose();
    router.push('/articles/create');
  };

  return (
    <Stack spacing={3}>
      <Heading fontSize="18px" fontWeight="600" color="brandPrimary.500">
        Publish as
      </Heading>
      <VStack gap={3}>
        <PublishUserCompanyCard
          profile={userProfileInfo}
          isCompany={false}
          isSelected={selectedUser}
          setValue={(e) => {setSelectedUser(e); dispatch(setPublishingAsData(e));}}
        />
        <VStack gap={3} maxH="200px" overflow="auto" w="100%">
          {!loading ? (
            companyPageList?.length && companyPageList ? (
              companyPageList?.map((company, i) => (
                <PublishUserCompanyCard
                  key={i}
                  profile={company}
                  isCompany={true}
                  isSelected={selectedUser}
                  setValue={(e) => {setSelectedUser(e); dispatch(setPublishingAsData(e));}}
                />
              ))
            ) : (
              <></>
            )
          ) : (
            <SectionLoader />
          )}
        </VStack>
      </VStack>
      {userProfileInfo?.is_staff && (
            <>
      <hr />
      <VStack alignItems="flex-start" mb={3} w="100%">
        <Heading fontSize="18px" fontWeight="600" color="brandPrimary.500">
          Publish to
        </Heading>
        <RadioGroup
          onChange={(e) => handleToChange(e)}
          defaultValue={publishTo}
          w="100%"
        >
          <HStack justifyContent="space-between" w="100%" mb={3}>
            <HStack gap={3}>
              <Box bg="brandGray.300" p={3} borderRadius="4px">
                <IndividualArticleIcon />
              </Box>
              <Text fontWeight="medium" color="black">
                Individual article
              </Text>
            </HStack>
            <Radio value="1" />
          </HStack>
          {userProfileInfo?.is_staff && (
            <>
              <HStack justifyContent="space-between" w="100%" mb={3}>
                <HStack gap={3}>
                  <Box bg="brandGray.300" p={3} borderRadius="4px">
                    <MyBridgeArticleIcon />
                  </Box>
                  <Text fontWeight="medium" color="black">
                    Admin (myBridge article)
                  </Text>
                </HStack>
                <Radio value="3" />
              </HStack>
              <HStack justifyContent="space-between" w="100%" mb={3}>
                <HStack gap={3}>
                  <Box bg="brandGray.300" p={3} borderRadius="4px">
                    <MyBridgeMewsIcon />
                  </Box>
                  <Text fontWeight="medium" color="black">
                    Admin (myBridge news)
                  </Text>
                </HStack>
                <Radio value="2" />
              </HStack>
            </>
          )}
        </RadioGroup>
      </VStack> </>)}
      {!isPageComp && (
        <HStack justifyContent="flex-end" gap={3}>
          <Button variant="secondary" onClick={() => onClose(true)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleNextStep()}>
            Next
          </Button>
        </HStack>
      )}
    </Stack>
  );
};

export default PublishAsComponent;
